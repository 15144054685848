<template>
  <div>
    <label for="text">
      City
      <span class="star" v-if="displayRequired">*</span>
    </label>
    <el-select
      v-loading="getCitiesLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      placeholder="Select City"
      v-model="city.city"
      filterable
      :filter-method="updatFilter"
      :disabled="!city.state || disableField"
      default-first-option
      
      clearable
    >
      <el-option
        v-for="city in filterCities"
        :key="city"
        :label="city"
        :value="city"
      />
    </el-select>
    <p class="err" v-if="validataionErrors && validataionErrors[errorKey]">
      {{ validataionErrors[errorKey] }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("help", [
      "getAllCities",
      "getCitiesLoading",
      "getStateChanged",
    ]),
    getErrorKey() {
      return `city`;
    },
    filterCities() {
      if (this.filterString && this.filterString.length) {
        return [...this.allCities].filter(
          (option) =>
            option.toLowerCase().indexOf(this.filterString.toLowerCase()) == 0
        );
      } else {
        return [...this.allCities];
      }
    },
  },
  props:{    
    cityData: { required: true },
    validataionErrors: { required: false },
    errorKey: { required: false },
    displayRequired: { default: true },
    disableField: { type: Boolean, default: false },
  },
  data() {
    return {
      filteredCities: [],
      includeCurrentPayor: true,
      filterString: "",
      city: "",
      formData: {},
      allCities: [],
    };
  },
  async mounted() {
    this.city = this.cityData;
  },
  methods: {
    setAllCities() {
      this.allCities = [...this.getAllCities];
    },

    updatFilter(filterValue) {
      this.filterString = filterValue;
    },
    async fetchCities(selectedState, loading = true) {
      if (!selectedState) {
        return;
      }

      let params = {
        state: selectedState,
        loading,
      };
      await this.$store.dispatch("help/fetchAllCities", params);
      this.setAllCities();
    },
  },

  watch: {
    cityData: {
      immediate: true,
      deep: true,
      handler: function () {
        if (this.cityData.state && this.cityData.state != this.formData.state) {
          this.fetchCities(this.cityData.state);
        }

        this.city = this.cityData;
        this.formData = { ...this.city };
      },
    },
  },
};
</script>

<style scoped>
.err {
  margin-top: 2px !important;
  color: #eb5757;
  font-size: 12px;
  min-height: 20px;
  white-space: nowrap;
  margin-bottom: -30px;
  line-height: 20px;
  font-weight: 500;
  padding-left: 2px;
}
</style>
